// Angular Import
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DealsService } from '../../../services/deals.service';
// React Imports
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { DealsModel } from 'src/app/models/main/deals/deals.model';
import { SearchModel } from 'src/app/models/main/deals/search.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GeodataModel } from 'src/app/models/main/geodata/geodata.model';
import { catchError, map } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-provinces-banner',
  templateUrl: './provinces-banner.component.html',
  styleUrls: ['./provinces-banner.component.scss']
})
export class ProvincesBannerComponent implements OnInit, OnDestroy {
  public banner_path: string;
  public region: string;
  public ipAddress: string;
  public innerWidth: any;
  public ProvinceVideoLink: string;
  public imgsrc: any;
  customOptions: OwlOptions;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public dynamic_image:any;
  public dynamic_cover_image:string;

  onResize(event) {
    //console.log('Welcome to onResize', window.innerWidth);
    this.innerWidth = window.innerWidth;

    let yourCountry = document.querySelectorAll(".yourcountry"); //.setAttribute("style", "width: 70%;");
    yourCountry.forEach((element) => {
      //console.log('Inner Page');
      if (this.innerWidth > 700) {
        element.setAttribute("style", "width: 70%;");
      } else {
        element.setAttribute("style", "width: 80%;");
      }
    });
  }

  ngAfterViewInit() {
    //this.banner_path = "assets/images/provinces/western-cape/summer/afternoon.png";
    //alert(this.innerWidth);
    //this.getBanner();
    //this.buildBannerPath();
    //this.getBanner();
    this.getLocation();
  }

  constructor (
    private readonly formBuilder: FormBuilder,
    private data: DealsService,
    private renderer: Renderer2,
    private httpClient: HttpClient
  ) { }

 
  public ngOnInit(): void {
    // Set Carousel Options
    this.customOptions = {
      autoplay: true,
      loop: false, //loop: true, // when loading more than one item
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      autoWidth:false,
      margin: 0,
      dots: true,
      navSpeed: 700,
      navText: ['', ''],
      autoHeight: false,
      center: true,
      items: 1,
      responsive: {
        0: {
          items: 1
        },
        740: {
          items: 1
        },
        940: {
          items: 1
        },
        1024: {
          items: 1
        },
        1400: {
          items: 1
        }
      },
      nav: false
    };

    this.dynamicBannerFun();
   
  
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    
   this.dynamicBannerFun()
   
  }
 

  public ngOnDestroy(): void {

  }
 
  
 
  dynamicBannerFun(){
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth <481 && this.getScreenWidth <= 767){
   
      // this.dynamic_cover_image="assets/images/Mobi-414x460-bg.jpg"
      // this.dynamic_image="assets/images/Masthead_Banner_367x485px.png"
      this.dynamic_image="assets/images/Masthead_BannersMasthead-Summner-Mobi-Banner-.png"
   
      // this.dynamic_cover_image= "assets/images/travelweek2022/travelweek2022_mobile.png"
    }
    else if(this.getScreenWidth >= 768 && this.getScreenWidth <= 1023){
      
      // this.dynamic_cover_image="assets/images/Tablet-768x450-bg.jpg"
      // this.dynamic_image="assets/images/Masthead_Banner_820x1058px.png"
      this.dynamic_image="assets/images/Masthead_BannersMasthead-Desktop-Banner_tab_820x1058px.png"
      // this.dynamic_cover_image= "assets/images/travelweek2022/travelweek2022_desktop.png"
      
    }
    else{
     
      // this.dynamic_cover_image="assets/images/Desktop-1920x810_bg.png"
        // this.dynamic_image="assets/images/Masthead_Banner_1680x611px.png"
        this.dynamic_image="assets/images/Masthead_BannersMasthead-Desktop-Banner_1680x611px.png"
      //this.dynamic_cover_image= "assets/images/travelweek2022/travelweek2022_desktop.png"

    }
   
  }
  //@HostListener('window:resize', ['$event'])
  //getScreenSize(event?) {
  //      this.innerWidth = window.innerWidth;
  //
  //      if(this.innerWidth > 700){
  //        //alert(this.innerWidth);
  //        document.getElementById("homebanner").setAttribute("data-jarallax-video", "https://www.youtube.com/watch?v=Lf82GWDgqMw");
  //      }else{
  //        //alert(this.innerWidth);
  //        this.buildBannerPath();
  //        document.getElementById("homebanner").setAttribute("data-jarallax-video", this.banner_path);
  //        //document.getElementById("homebanner").setAttribute("style", "background-image: url("+this.banner_path+")");
  //      }
  //      //alert(this.innerWidth);
  //}


  public buildBannerPath(): void {
    var date = new Date();
    var province = "";
    var season = this.getSeason(date);
    var tod = this.getTimeOfDay(date);


    this.httpClient.jsonp<any>('http://api.ipstack.com/' + this.ipAddress + '?access_key=ed506ce3782dcee72067f492e13e77b9&fields=region_name', 'callBack').subscribe((res: GeodataModel) => {
      province = res.region_name.toLowerCase();
      province = province.replace(' ', '-');
      province = province.replace(' ', '-');

      this.banner_path = "assets/images/provinces/" + province + "/" + season + "/" + tod + ".png";

      return this.banner_path;
    },
      (err: HttpErrorResponse) => {

        if (err.error instanceof Error) {
          //console.log("Client-side error occured.");
        } else {
          //console.log("Server-side error occured.");
        }
      });
  }

  getTimeOfDay(date: Date): string {
    let tod = date.getHours();
    let time = "";
    if (tod >= 6 && tod < 12) {
      time = "morning";
    }
    else if (tod >= 12 && tod <= 18) {
      time = "afternoon";
    }
    else {
      time = "evening";
    }

    return time;
  }

  getSeason(date: Date): string {
    let season = "";
    let month = date.getMonth();

    if ((month >= 0 && month <= 2) || month == 11) {
      season = "summer";
    }
    else if (month >= 9 && month <= 10) {
      season = "spring";
    }
    else if (month >= 3 && month <= 5) {
      season = "fall";
    }
    else {
      season = "winter";
    }
    return season;
  }

  getLocation() {
    var region = "";
    if (navigator.geolocation) {
      var longitude = 0;
      var latitude = 0;

      navigator.geolocation.getCurrentPosition((position) => {
        longitude = position.coords.longitude;
        latitude = position.coords.latitude;
        //alert(longitude);
        this.codeLatLng(latitude, longitude);
        //this.codeLatLng("-29.830740", '30.955550');
      }, errorCallback);
      function randomizer(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }
      function errorCallback(error) {
        var random = randomizer(2, 12);
        let img = "";

        if (random < 10) {
          img = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + random + '.jpg';
        }
        else {
          img = 'assets/images/provincemap/WC/SAT_IMGS_WC' + random + '.jpg';
        }

        let path = (`url(${img})`);

        // document.getElementById("homebanner").setAttribute("style", "background-image: " + path + ";");

        if (error.code == error.PERMISSION_DENIED) {
          //console.log("Get location by ip");
        }
      }
    } else {
      alert("No support for geolocation");
    }

    //return this.httpClient.get<any>('http://api.ipstack.com/102.250.6.196?access_key=ed506ce3782dcee72067f492e13e77b9&fields=region_name');



    //return region;
  }

  getBanner() {
    let headersL = new Headers();
    let test: string;

    headersL.append('Content-Type', 'application/json');
    headersL.append('Accept', 'application/json');
    headersL.append('Origin', 'http://localhost:4200');

    fetch("http://api.ipify.org/?format=json", {
      mode: 'cors',
      method: 'GET',
      headers: headersL
    })
      .then(response => response.json())
      .then(json => {
        this.ipAddress = json.ip;
        //alert(json.ip);
        this.getBannerRandom();

      })
      .catch(error => {
        //console.log('Authorization failed : ' + error.message)
      });

    //this.httpClient.get("http://api.ipify.org/?format=json", { headers: headersL, }).subscribe((res:any)=>{
    //  return this.ipAddress = res.ip;
    //});
  }

  codeLatLng(lat, lng) {

    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();
    let province = "";
    let img = "";
    let path = "";

    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          //formatted address
          //alert(results[0].formatted_address)
          //find country name
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var b = 0; b < results[0].address_components[i].types.length; b++) {

              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                //this is the object you are looking for
                var city = results[0].address_components[i];
                province = city.long_name;
                //alert(province);
                break;
              }
            }
          }
          function randomizer(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
          }
          //city data
          //alert(city.short_name + " " + city.long_name);
          switch (province.toLowerCase().replace('-', ' ')) {
            case 'northern cape': {
              var random = randomizer(1, 16);

              if (random < 10) {
                img = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/NC/SAT_IMGS_NC' + random + '.jpg';
              }
              path = (`url(${img})`);
              break;
            }
            case 'western cape': {
              var random = randomizer(2, 12);

              if (random < 10) {
                img = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/WC/SAT_IMGS_WC' + random + '.jpg';
              }

              path = (`url(${img})`);
              break;
            }
            case 'north west': {
              var random = randomizer(1, 10);

              if (random < 10) {
                img = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/NW/SAT_IMGS_NW' + random + '.jpg';
              }
              path = (`url(${img})`);

              break;
            }
            case 'free state': {
              var random = randomizer(1, 13);

              if (random < 10) {
                img = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/FS/SAT_IMGS_FS' + random + '.jpg';
              }

              path = (`url(${img})`);
              break;
            }
            case 'gauteng': {

              var random = randomizer(1, 9);


              if (random < 10) {
                img = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/GP/SAT_IMGS_GP' + random + '.jpg';
              }
              path = (`url(${img})`);
              break;
            }
            case 'mpumalanga': {
              var random = randomizer(1, 11);

              if (random < 10) {
                img = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/MP/SAT_IMGS_MP' + random + '.jpg';
              }
              path = (`url(${img})`);
              break;
            }
            case 'limpopo': {
              var random = randomizer(1, 10);

              if (random < 10) {
                img = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/LMP/SAT_IMGS_LMP' + random + '.jpg';
              }
              path = (`url(${img})`);
              break;
            }
            case 'kwazulu natal': {
              var random = randomizer(1, 8);
              if (random < 10) {
                img = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/KZN/SAT_IMGS_KZN' + random + '.jpg';
              }
              path = (`url(${img})`);
              break;
            }
            case 'eastern cape': {
              var random = randomizer(1, 12);

              if (random < 10) {
                img = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + random + '.jpg';
              }
              else {
                img = 'assets/images/provincemap/EC/SAT_IMGS_EC' + random + '.jpg';
              }
              path = (`url(${img})`);

              break;
            }
            default: {
              var random = randomizer(1, 9);
              //this.randomProvince(random);
              path = (`url(${img})`);
            }

          }
          document.getElementById("homebanner").setAttribute("style", "background-image: " + path + ";");
        } else {
          alert("No results found");
        }
      } else {
        alert("Geocoder failed due to: " + status);
      }
    });
  }

  callApi(Longitude: number, Latitude: number) {
    const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`
    //Call API
  }
  randomizer(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getBannerRandom(): void {

    let province = "";
    var random = this.randomizer(2, 12);
    //this.randomProvince(random);
    if (random < 10) {
      this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + random + '.jpg';
    }
    else {
      this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC' + random + '.jpg';
    }
    this.banner_path = (`url(${this.imgsrc})`);

    this.httpClient.get('http://api.ipstack.com/' + this.ipAddress + '?access_key=ed506ce3782dcee72067f492e13e77b9&fields=region_name').subscribe((res: GeodataModel) => {
      province = res.region_name.toLowerCase();
      //alert(province);
      switch (province) {
        case 'northern cape': {
          var random = this.randomizer(1, 16);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/NC/SAT_IMGS_NC' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'western cape': {
          var random = this.randomizer(2, 12);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/WC/SAT_IMGS_WC' + random + '.jpg';
          }

          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'north west': {
          var random = this.randomizer(1, 10);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/NW/SAT_IMGS_NW' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);

          break;
        }
        case 'free state': {
          var random = this.randomizer(1, 13);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/FS/SAT_IMGS_FS' + random + '.jpg';
          }

          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'gauteng': {
          var random = this.randomizer(1, 9);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/GP/SAT_IMGS_GP' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'mpumalanga': {
          var random = this.randomizer(1, 11);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/MP/SAT_IMGS_MP' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'limpopo': {
          var random = this.randomizer(1, 10);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/LMP/SAT_IMGS_LMP' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'kwazulu natal': {
          var random = this.randomizer(1, 8);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/KZN/SAT_IMGS_KZN' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);
          break;
        }
        case 'eastern cape': {
          var random = this.randomizer(1, 12);

          if (random < 10) {
            this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC0' + random + '.jpg';
          }
          else {
            this.imgsrc = 'assets/images/provincemap/EC/SAT_IMGS_EC' + random + '.jpg';
          }
          this.banner_path = (`url(${this.imgsrc})`);

          break;
        }
        default: {
          var random = this.randomizer(1, 9);
          //this.randomProvince(random);
          this.banner_path = (`url(${this.imgsrc})`);
        }

      }
      // document.getElementById("homebanner").setAttribute("style", "background-image: " + this.banner_path + ";");
    },
      (err: HttpErrorResponse) => {

        if (err.error instanceof Error) {
          //console.log(err);
          //console.log("Client-side error occured.");
        } else {
          //console.log("Server-side error occured.");
        }
      });

    //province = province.toLowerCase();


  }

}
