<div class="shotleft-provinces-banner">
  <owl-carousel-o [options]="customOptions">
    <!-- <ng-template carouselSlide> -->
      <div class="shotleft-provinces-banner--hero bg-holder bg-overlay-50">
      <!-- <div class="shotleft-provinces-banner--hero bg-holder bg-overlay-black-50" style="background-image: url('assets/images/provincemap/WC/SAT_IMGS_WC07.jpg');"> -->
        <div class="shotleft-provinces-banner--hero bg-holder bg-overlay-black-50">
        <div class="container-fluid p-0 shotleft-provinces-banner--content">
          <div>
            <div class="col-lg-12 p-0">
             <a href="/" class="foundMore">
                <!-- <img class="country-text Masthead" [src]="dynamic_image"  alt="It's your country. Enjoy it." /> -->
              </a>
           
    
            <!-- <img  src= {{dynamic_image}}  alt="It's your country. Enjoy it." /> -->
           
               <!-- <nav aria-label="breadcrumb" class="d-flex justify-content-center finoutmore"> -->
<!-- <img src="assets/images/Shotleft_Desktop_Banner.png" alt="It's your country. Enjoy it." />  -->
<!-- <img src="assets/images/Masthead Desktop Banner.png" alt="It's your country. Enjoy it." />  -->

<!-- <img src="assets/images/its_your_country.svg" alt="It's your country. Enjoy it." /> -->
<!-- <img src="assets/images/travelweek2022/travelweek2022.png" alt="It's your country. Enjoy it." /> -->
              <!-- </nav>  -->
            </div>
          </div>
        </div>
      </div>
      </div>
    <!-- </ng-template> -->

    <ng-template carouselSlide>
      <div class="shotleft-provinces-banner--hero bg-holder carousel-A">
        <div class="container shotleft-provinces-banner--content">
          <div class="row justify-content-between">
            <!-- <div class="col-lg-5 nomargin-img justify-content-start">
              <img src="assets/images/provincemap/WC/heading.png" alt="It's your country. Enjoy it." />
            </div> -->
            <!-- <div class="col-lg-4 nomargin-img d-flex justify-content-end">
              <img src="assets/images/provincemap/WC/offer.png" alt="Experiences Over Gifts. Book your baecation now! #travelwisemzansi" />
            </div> -->
          </div>
        </div>
      </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
        <div class="shotleft-provinces-banner--hero bg-holder carousel-B">
          <div class="container shotleft-provinces-banner--content">
            <div class="row justify-content-between">
            </div>
          </div>
        </div>
      
    </ng-template> -->
    <!-- <ng-template carouselSlide>
        <div class="shotleft-provinces-banner--hero bg-holder carousel-C">
          <div class="container shotleft-provinces-banner--content">
            <div class="row justify-content-between">
            </div>
          </div>
        </div>
    </ng-template> -->
    
    
    <!-- <ng-template carouselSlide>
      <a href="/seat-at-the-table">
        <div class="shotleft-provinces-banner--hero bg-holder carousel-C" style="background-image: url('assets/images/provincemap/MH/34239MMS_SAT-MastheadBanner-1680x611-C.jpg');">
          <div class="container shotleft-provinces-banner--content">
            <div class="row justify-content-between">
              <div class="col-lg-5 mb-4 justify-content-start">
                <img src="assets/images/its_your_country.svg" alt="It's your country. Enjoy it." />
              </div>
              <div class="col-lg-4 nomargin-img d-flex justify-content-end">
                <img src="assets/images/easter/offering.png" alt="Set your table anywhere in MZANSI this easter. #travelwisemzansi" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </ng-template> -->
      <!-- <ng-template carouselSlide>
        <div class="shotleft-provinces-banner--hero easterSlide bg-holder">
          <div class="container shotleft-provinces-banner--content" style="padding-top: 280px;">
            <div class="row justify-content-between">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb" class="d-flex justify-content-center">
                  <ol class="breadcrumb" style="font-size: 16px;">
                    <li style="margin-right:auto;" class="breadcrumb-item">
                      <a href="/seat-at-the-table">
                        <img style="width:14px;margin-top:0px;margin-right:20px;" src="/assets/images/left-arrow.png">
                        Explore more
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </ng-template> -->
  </owl-carousel-o>
</div>

<!--
<section id="homebanner" class="page-title bg-holder bg-overlay-black-50"
  style="padding:0 0 0 0;background-image: url('assets/images/provincemap/WC/SAT_IMGS_WC07.jpg');height:50%;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="banner-image-container">
          <div style="height:420px;padding-top:130px;">
            <h1><img id="yourcountry" src="assets/images/its_your_country.svg" alt="It's your country. Enjoy it." />
            </h1>
          </div>
        </div>
        <nav aria-label="breadcrumb ">
          <ol class="breadcrumb" style="font-size: 16px;">
            <li style="margin-right:auto;" class="breadcrumb-item">
              <a href="/itinerary">
                <img style="width:14px;margin-top:0px;margin-right:20px;" src="/assets/images/left-arrow.png">
                Get out there
              </a>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</section>
-->
