<section *ngIf="this.product != undefined" class="space-ptb" style="margin-top:25px;">
    <div class="container">
      <div class="row align-content-end">
        <div class="col-lg-12">
          <div class="d-sm-flex d-block align-items-center">
            <img class="img-fluid avatar avatar-xll" src="{{companyLogo}}" alt="">
            <div class="ml-sm-4 ml-0 mt-3 mt-sm-0">
              <h1 style="font-size:30px;" class="mb-3"> {{title}}
            </h1>
              <div class="d-md-flex d-block">
                <strong class="text-dark d-inline-block mr-2"><i class="fas fa-phone"></i></strong>{{contact_number}} &nbsp;&nbsp;
                <strong class="text-dark d-inline-block mr-2"><i class="fas fa-map-marker-alt"></i></strong>{{location}}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</section>

<section class="">
    <div class="container">
      <div class="row align-items-center">
        <div *ngIf="this.product != undefined" class="col-md-12 mt-4 mt-md-0">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="listing-detail-page">
                          <div class="listing-detail-box mb-3" >
                            <div class="slider slider-for" style='background-image: url( {{mainPhoto}} );display:block;width:100%;height:600px;background-size: cover;background-position: center center;background-repeat: no-repeat;'>
                                
                                <div class="SpecialdiscountSmall">&nbsp;&nbsp;&nbsp;&nbsp;{{currency}} {{price}}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                
                                <!--<div *ngIf="price !== newPrice">
                                    <div class="SpecialdiscountSmall">&nbsp;&nbsp;WAS {{currency}} {{price}} &nbsp;</div>
                                    <div class="SpecialNewPrice">&nbsp;&nbsp;NOW {{currency}} {{newPrice}} &nbsp;</div>
                                </div>
                                <div *ngIf="discount === '0'">
                                    <div class="NoDiscountSmall">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{currency}} {{price}}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                </div>-->
                            </div>
                            <ngx-slick-carousel style="margin-left: auto;margin-right: auto;" class="carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig" >
                            <div ngxSlickItem *ngFor="let img of images_dash" class="slide">
                                  <div (click)="changePhoto(img.mx_image)" style='cursor:pointer;background-image: url( {{img.mx_image}} );display:block;height:150px;background-size: cover;background-position: center center;background-repeat: no-repeat;' ></div>
                            </div>
                            </ngx-slick-carousel>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <ul class="list-unstyled listing-detail-meta mb-0 mt-4">
            <li>
                <div class="feature-info-02 mb-lg-5 mt-5">
                    <div class="feature-icon">
                        <a id="book-now-button" class="btn btn-primary mt-2 mt-sm-0 mr-sm-2 mx-1 btnBook"
                           (click)="bookNow(bookingLink)">Book
                            Now</a>
                    </div>
                </div>
            </li>
            <li class="social-share mt-5"><a href="#"><i class="fas fa-share-alt"></i> Share</a>
                <div class="social-box">
                    <ul class="list-unstyled">
                        <li><a class="facebook" target="_blank" href="{{facebook}}"><i class="fab fa-facebook-f"></i>Facebook
                            </a></li>
                        <li><a class="twitter" target="_blank" href="{{twitter}} {{overview}}"><i
                                    class="fa-brands fa-x-twitter"></i>twitter </a></li>
                        <li><a class="linkedin" target="_blank" href="{{email}}"><i class="fas fa-envelope"></i>email </a></li>
                    </ul>
                </div>
            </li>
        </ul>
        <!--<div class="col-md-5 mt-4 mt-md-0">
            <div class="container-fluid p-0">
                <img class="img-deals" src="assets/images/deals/Specials.png" alt="">
                        <div class="center-specials-text">&nbsp;&nbsp;R99 Specials&nbsp;&nbsp;</div>
                <div class="center-specials-description">5 March - 31 May 2021</div>

            </div>
        </div> -->

      </div>
    </div>
  </section>

<section class="space-ptb bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-12">
                        <div class="shotleft-heading">
                            <h2 class="shotleft-heading">{{dealDescription}}</h2>
                        </div>
                        <!--<div class="mt-3">
                            <p>{{overview}}</p>
                        </div>-->
                        <div class="mt-3" innerHtml="{{overview}}">

                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="itinerary.length > 0">
                    <div class="col-12 mt-4">
                        <div class="listing-detail-box mt-2">
                            <h2 class="shotleft-heading">Itinerary Breakdown</h2>
                            <div class="row border-bottom pb-4 mt-4" *ngFor="let iti of itinerary ; let i = index" [attr.data-index]="i">
                                <div class="col-md-11">
                                    <div class="px-md-4 mt-3 mt-md-0">
                                        <div class="d-flex align-items-center">
                                            <h6 class="mt-0">{{iti.mx_name}}</h6>
                                        </div>
                                        <p>{{iti.mx_summary}}</p>
                                        <div class="row mb-3" *ngIf="iti?.mx_image">
                                            <div class="col-md-2 col-sm-3 mb-3 mb-sm-0">
                                                <a class="popup popup-single" data-toggle="tooltip" data-placement="top" title="Zoom" target="_blank" href="{{iti.mx_image}}">
                                                    <img class="img-fluid border-radius" src="{{iti?.mx_image}}" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4">
                <div class="sidebar mb-0">
                    <div class="widget">
                        <div class="widget-title">
                            <h3 style="margin-top:2%;font-size:22px;letter-spacing: 0em;font-weight: 500;font-style: normal;"> Location </h3>
                        </div>
                        <div class="widget-content">
                            <!--<div #map style="height: 200px; width: 100%;"></div>-->
                            <div style="height: 200px; width: 100%;">
                                <img src="{{staticMapUrl}}">
                            </div>
                            <ul class="list-unstyled mb-3 mt-4 d-block">
                                <li class="mr-4 mb-3"><strong class="text-dark d-inline-block mr-2"><i class="fas fa-map-marker-alt"></i></strong>{{location}}</li>
                                <li class="mb-2" style="margin-left: 6%;"><h3 style="font-size:22px;letter-spacing: 0em;font-weight: 500;font-style: normal;" class="text-dark d-inline-block mr-2"><a target="_blank" href="http://maps.google.com/?q={{mapCenter}}&ll={{mapCenter}}&z=9">Get Directions</a>
                                </h3></li>
                                <li *ngIf="product != undefined" class="mb-2">
                                    <strong class="text-dark d-inline-block mr-2">

                                        <i class="fas fa-envelope"></i>

                                    </strong><a href="mailto:{{product.cf_product.cf_email_address}}">{{product.cf_product.cf_email_address}}</a>
                                </li>
                                <li *ngIf="product != undefined" class="mb-2">
                                <strong class="text-dark d-inline-block mr-2"><i class="fas fa-phone"></i></strong>
                                    {{product.cf_product.cf_contact_number}}
                                </li>
                                <li *ngIf="product != undefined" class="mb-2">
                                    <strong class="text-dark d-inline-block mr-2">
                                        <i class="fas fa-link"></i></strong><a target="_blank" href="{{ productUrl }}">{{ productUrl }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>

</section>

<app-featured-deals-small [FilterData]="searchModel" [dealsData]="dealsProducts"></app-featured-deals-small>
