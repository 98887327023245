<!--<section class="" style="background-image: url(assets/images/Chintsa-beach.png);background-size: cover;background-position: center center;background-repeat: no-repeat;">
  <div class="row">
    <div class="col-lg-8">
      <div class="form-group">
        <div class="col-md-8">
          <h2 class="text-white mt-4">
            <a style="text-decoration: underline; -webkit-text-decoration-color: #FDCA13; /* Safari */ text-decoration-color: #FDCA13; text-underline-position: under;" >Zwakala, Tloho</a>, come over!</h2>

          <p class="text-white mb-4 mb-md-5">
            Ekse my friend, the country’s open and waiting for you to travel and discover all the amazing things that it has to offer.
            <br/> <br/>
            What are you looking for? Because Mzansi has it all. Beautiful people and beautiful places. From majestic rivers to sun-soaked beaches, from breath-taking mountains to valleys and views that will leave you speechless. From gritty wildlife to vibey nightlife there’s literally nothing you can’t experience. So, get out there, take a trip and have some fun.        
          </p>
        </div>
      </div>

    </div>
    
    <div class="col-lg-4 text-left">
      <iframe style="margin-top: 5%;width: 90%;margin-bottom: 5%;height: 80%;" src="https://www.youtube.com/embed/qglyqwLIoik" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
    </div>

  </div>  
</section>-->

<section class="space-ptb bg-holder bg-overlay-black-50 bg-image-shotleft" [style.backgroundImage]='background'>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h2 class="text-white mt-4">
          <a style="text-decoration: underline; -webkit-text-decoration-color: #FDCA13; /* Safari */ text-decoration-color: #FDCA13; text-underline-position: under;" >It's Your Country, </a> Enjoy It!
        </h2>

        <p class="text-white" style="text-align: justify;">Mzansi, Love is in the Air - And So Is Your Next Sho't Left.
          <br /> <br />
          Roses are romantic, chocolates are sweet, but sunset dinners on the coast are sweeter. This Month of Love, skip the predictable boring gifts and choose a Sho’t Left with your forever yena.
          <br /><br />
          From cozy bush escapes, candlelit dinner under the stars to dreamy beachfront getaways, Sho’t Left has the perfect Valentine’s deals for every kind of couple to make your love story truly special.
          <br /><br />
          Click "Explore More” and make this Month of Love a chapter in your love story you’ll always remember.
          <br /><br />
        </p>
      </div>
      <div class="col-lg-5 mt-0">
      
       <!--<iframe style="margin-top: 5%;width: 90%;margin-bottom: 5%;height: 80%;" src="https://www.youtube.com/embed/iu57X3TIdbg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->   


      </div>
    </div>
  </div>
</section>