<section *ngIf="locationsArray.length > 0" class="space-pb" style="padding-top:20px;background-color:#F6F6F6;">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h2 style="font-weight:heavy;">Today’s travel deals</h2>
            <h4>Heita! So, you want to get out there and you’re looking for something great within your budget. Here are some of the hottest deals for stays and activities to suit the kind of experience you’re looking for. It’s your country, Enjoy it!</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          
          <owl-carousel-o [options]="customOptions" >
            <ng-container *ngFor="let loc of locationsArray ; let i = index">
              <ng-template carouselSlide [id]="">           
                <div class="location-item-02 bg-holder text-center" style="background-image: url({{loc.cf_images[0]?.mx_image}});border-top-left-radius: 4%;
                        border-top-right-radius: 4%;" >
                  <div *ngIf="loc.cf_discount != '0'" class="Specialdiscount">&nbsp;&nbsp;&nbsp;&nbsp;{{loc.cf_discount}}% Off&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <!-- Icons -->
                <!-- <a class="position-relative" href="#">London</a>
                <a class="position-relative listing-list" href="#">10 listing</a> -->
                <!-- Icons -->
                  <div class="listing-image">
                    <div class="listing-quick-box">
                    <!--<a class="popup popup-single" href="{{loc.cf_images[0]?.mx_image}}" data-toggle="tooltip" data-placement="top" title="Zoom"> <i class="fas fa-search-plus"></i> </a>-->
                    <!-- <a class="like" style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="Like"> <i class="fas fa-heart"></i> </a> -->
                    
                   <!--<li class="iconsyellow social-share"><a class="share" style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="share"> <i class="fas fa-code-branch"></i> </a>-->
                    <a class="iconsyellow social-share" data-toggle="tooltip" data-placement="top" title="Zoom"> <i class="fas fa-code-branch"></i>
                      <div class="social-box">
                        <a target="_blank" href="{{facebook}}"><i class="fab fa-facebook-f">&nbsp;</i></a>
                      &nbsp;&nbsp;
                      <a target="_blank" href="{{twitter}}{{loc.title}}"><i class="fa-brands fa-x-twitter" >&nbsp;</i></a>                                   
                      
                      &nbsp;&nbsp;

                         <!-- <a target="_blank" href="{{linkedin}}"><i class="fab fa-linkedin-in"></i>&nbsp;</a> -->
                        <a target="_blank" href="mailto:?subject=South African Tourism:  {{loc.title}} &body= {{loc.cf_deal_description}} {{break}} {{email}}"><i class="fas fa-envelope"></i>&nbsp;</a>

                          <!-- <li class="socialmedia"><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                         <li class="socialmedia"><a href="#"><i class="fab fa-instagram"></i></a></li> -->
                     </div> </a>
                  </div>
                  <!-- <div class="listing-quick-box-custom">
                    <a style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="food"> <i class="fas fa-utensils"></i> </a>
                    <a style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="plane"> <i class="fas fa-plane"></i> </a>
                    <a style="margin-left:8px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="bus"> <i class="fas fa-bus"></i> </a>
                    <a style="margin-left:10px;cursor:pointer;" data-toggle="tooltip" data-placement="top" title="hotel"> <i class="fas fa-hotel"></i> </a>
                  </div> -->
                </div>

                
                  
                
              </div>
              <div class="listing-item">
                <div class="listing-details">
                  <div class="listing-details-inner">
                    <div class="listing-title">
                      <h6><a>{{loc.title.substring(0,60)}}</a></h6>
                    </div>
                    <div class="listing-info">
                      <div class="mb-0">{{loc.cf_deal_summary.substring(0,200)}}</div>
                    </div>
                    <br/>
                    <div>
                      <a class="listing-loaction" href="#"> <i class="fas fa-map-marker-alt"></i> {{loc.cf_destinations[0]?.mx_city}}</a>
                    </div>
                  </div>
                  <div class="listing-bottom">    
                    <div class="col-6 text-left listing-shotleft">
                      <a class="listing-price">ZAR {{loc.cf_price}}</a>
                    </div>
                    <div class="col-6 text-right listing-shotright">
                      <a (click)="goToDeals(loc.url_title)" class="listing-open">Explore more ></a>
                    </div>             
                  </div>
                </div>
              </div>
            </ng-template>
            
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>